import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  const perPage = ref(25)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const typeFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, typeFilter], () => {
    refetchData()
  })

  const fetchData = (ctx, callback) => {
    store
      .dispatch('seo_templates/paginate', {
        per_page: perPage.value,
        page: currentPage.value,
        sort_by: sortBy.value,
        sort_desc: isSortDirDesc.value,
        type: typeFilter.value,
      })
      .then(response => {
        const { data, meta: { total } } = response.data

        callback(data)
        totalItems.value = total
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchData,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
    typeFilter,
  }
}
